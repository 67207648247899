import {NgcCookieConsentConfig} from "ngx-cookieconsent";
import {environment} from "../../../environments/environment";

export const constants = {
    defaultPrimaryUrl: 'https://lctbo.blob.core.windows.net/yanzi-public/menu-items/primary/500_default.webp',
    defaultThumbnailUrl: 'https://lctbo.blob.core.windows.net/yanzi-public/menu-items/thumbnails/100_default.webp',
    primaryPhotoFolder: 'https://lctbo.blob.core.windows.net/yanzi-public/menu-items/primary',
    thumbnailPhotoFolder: 'https://lctbo.blob.core.windows.net/yanzi-public/menu-items/thumbnails',
    basePhotoFolder: 'https://lctbo.blob.core.windows.net/yanzi-public',
}

export const cookieConsentConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.production ? 'yanzidelivery.com' : 'localhost:8100'
    },
    theme: 'classic',
    type: 'info',
    palette: {
        popup: {
            background: '#F8F5F1'
        },
        button: {
            background: '#eb182d'
        }
    },
    // elements: {
    //   messagelink: `
    //         <span id="cookieconsent:desc" class="cc-message">{{message}}
    //           <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="privacy" rel="noopener">{{cookiePolicyLink}}</a>,
    //           <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="privacy" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> and our
    //           <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="terms" target="_blank" rel="noopener">{{tosLink}}</a>
    //         </span>
    //         `,
    // },
    // content: {
    //   message: 'By using our site, you acknowledge that you have read and understand our ',
    //
    //   cookiePolicyLink: 'Cookie Policy',
    //   cookiePolicyHref: 'privacy',
    //
    //   privacyPolicyLink: 'Privacy Policy',
    //   privacyPolicyHref: 'privacy',
    //
    //   tosLink: 'Terms of Service',
    //   tosHref: 'terms',
    // }
};
